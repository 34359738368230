import React from 'react';

import { ErrorMessage as ErrorMessageRHF } from '@hookform/error-message';
import { Typography } from '@nestoca/ui';

type ErrorMessageProps = {
    name: string;
    errors?: any;
};

export const ErrorMessage = ({ name, errors }: ErrorMessageProps) => {
    if (!errors) {
        return null;
    }

    return (
        <ErrorMessageRHF
            name={name as any}
            errors={errors}
            render={({ message }) => <ErrorText>{message}</ErrorText>}
        />
    );
};

const ErrorText = (props: any) => (
    <Typography
        textColor="var(--color-status-error)"
        style={{ fontSize: '12px' }}
        {...props}
    />
);
