import React from 'react';

import { Controller, useWatch } from 'react-hook-form';
import { Flex } from 'reflexbox/styled-components';

import {
    SelectV2 as Select,
    TextInputComponent,
    PostalCodeInput,
} from 'components/forms';
import { Text } from 'components/text/text';
import {
    COUNTRIES_OPTIONS,
    PROVINCES_OPTIONS,
    US_STATES_OPTIONS,
} from 'constants/contries-states';
import { useI18n } from 'providers/i18n/use-i18n';
import { isEmpty, isRequired } from 'utils';

import { ErrorMessage } from './input-error-message';

export const AddressFields = ({
    name,
    defaultValue = {},
    required = false,
    errors,
}: any) => {
    const { i18n } = useI18n();
    const requiredRule = isRequired(i18n, required);

    const countryCode = useWatch({
        name: `${name}.countryCode`,
        defaultValue: defaultValue.countryCode,
    });

    return (
        <>
            <Flex flexDirection="column" width="100%">
                <Flex padding={3} width="100%" flexDirection="column">
                    <Text fontWeight="bold" tx="Country" />

                    <Controller
                        name={`${name}.countryCode`}
                        defaultValue={
                            required && isEmpty(defaultValue.countryCode)
                                ? 'CA'
                                : defaultValue.countryCode
                        }
                        render={({ field: rhfField, fieldState }) => (
                            <Select
                                data-testid={`${name}.countryCode`}
                                placeholder={i18n._(`Country`)}
                                id={`${name}.countryCode`}
                                error={fieldState.error}
                                options={COUNTRIES_OPTIONS(i18n)}
                                search
                                {...rhfField}
                            />
                        )}
                        rules={requiredRule}
                    />
                    <ErrorMessage
                        name={`${name}.countryCode`}
                        errors={errors}
                    />
                </Flex>
                <Flex padding={3}>
                    <Flex flex={1} flexDirection="column" mr="3">
                        <Text fontWeight="bold" tx="Street Number" />

                        <Controller
                            name={`${name}.streetNumber`}
                            defaultValue={defaultValue.streetNumber || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <TextInputComponent
                                    data-testid={`input-${rhfField.name}`}
                                    id={`${name}-streetNumber`}
                                    placeholder={i18n._(`Street Number`)}
                                    error={fieldState.error}
                                    {...rhfField}
                                />
                            )}
                            rules={requiredRule}
                        />
                        <ErrorMessage
                            name={`${name}.streetNumber`}
                            errors={errors}
                        />
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                        <Text fontWeight="bold" tx="Street Name" />

                        <Controller
                            name={`${name}.street`}
                            defaultValue={defaultValue.street || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <TextInputComponent
                                    data-testid={`input-${rhfField.name}`}
                                    id={`${name}-street`}
                                    placeholder={i18n._(`Street Name`)}
                                    error={fieldState.error}
                                    {...rhfField}
                                />
                            )}
                            rules={requiredRule}
                        />
                        <ErrorMessage name={`${name}.street`} errors={errors} />
                    </Flex>
                </Flex>
                <Flex padding={3}>
                    <Flex flex={1} flexDirection="column" mr="3">
                        <Text fontWeight="bold" tx="Unit" />

                        <Controller
                            name={`${name}.unit`}
                            defaultValue={defaultValue.unit || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <TextInputComponent
                                    data-testid={`input-${rhfField.name}`}
                                    id={`${name}-unit`}
                                    placeholder={i18n._(`Unit`)}
                                    error={fieldState.error}
                                    {...rhfField}
                                />
                            )}
                        />
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                        <Text fontWeight="bold" tx="City" />

                        <Controller
                            name={`${name}.city`}
                            defaultValue={defaultValue.city || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <TextInputComponent
                                    data-testid={`input-${rhfField.name}`}
                                    id={`${name}-city`}
                                    placeholder={i18n._(`City`)}
                                    error={fieldState.error}
                                    {...rhfField}
                                />
                            )}
                            rules={requiredRule}
                        />
                        <ErrorMessage name={`${name}.city`} errors={errors} />
                    </Flex>
                </Flex>
                <Flex padding={3}>
                    <Flex
                        flexDirection="column"
                        mr="3"
                        flex={{ grow: 2, shrink: 1 }}
                    >
                        <Text fontWeight="bold" tx="Province" />

                        <Controller
                            name={`${name}.stateCode`}
                            defaultValue={defaultValue.stateCode || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <Select
                                    data-testid={`${name}.stateCode`}
                                    placeholder={i18n._(
                                        countryCode === 'CA'
                                            ? 'stateCode'
                                            : 'state'
                                    )}
                                    id={`${name}.stateCode`}
                                    error={fieldState.error}
                                    options={
                                        countryCode === 'US'
                                            ? US_STATES_OPTIONS(i18n)
                                            : PROVINCES_OPTIONS(i18n)
                                    }
                                    search
                                    isClearable
                                    {...rhfField}
                                />
                            )}
                            rules={requiredRule}
                        />
                        <ErrorMessage
                            name={`${name}.stateCode`}
                            errors={errors}
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        <Text
                            fontWeight="bold"
                            tx={countryCode === 'CA' ? 'postalCode' : 'zipCode'}
                        />

                        <Controller
                            name={`${name}.postalCode`}
                            defaultValue={defaultValue.postalCode || ''}
                            render={({ field: rhfField, fieldState }) => (
                                <PostalCodeInput
                                    data-testid={`${name}.postalCode`}
                                    placeholder={i18n._(
                                        countryCode === 'CA'
                                            ? 'postalCode'
                                            : 'zipCode'
                                    )}
                                    id={`${name}.postalCode`}
                                    countryCode={countryCode}
                                    error={fieldState.error}
                                    {...rhfField}
                                />
                            )}
                            rules={requiredRule}
                        />
                        <ErrorMessage
                            name={`${name}.postalCode`}
                            errors={errors}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};
