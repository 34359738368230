import React, { useState, useCallback } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useFieldArray, Controller, useFormState } from 'react-hook-form';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { MoneyInputComponent, SelectV2 } from 'components/forms';
import { Text } from 'components/text/text';
import { ASSET_TYPE_OPTIONS, ASSET_TYPE } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';

import { ErrorMessage } from './input-error-message';


const AssetInput = ({
    type,
    amount,
    usedForDownpayment,
    onRemove,
    i18n,
    namePrefix,
    errors,
}) => (
    <Flex flexDirection="column" mb={3}>
        <Flex flexDirection="row" justifyContent="space-between">
            <Text fontWeight="bold" tx={ASSET_TYPE(i18n)[type]} />
            <Button onClick={onRemove} variant="ghost">
                <Trans id="delete" />
            </Button>
        </Flex>
        <Flex justifyContent="space-between">
            <Controller
                name={`${namePrefix}.amount`}
                defaultValue={amount}
                render={({ field: rhfField, fieldState }) => (
                    <Flex flexDirection="column" flex={1} mr={2}>
                        <MoneyInputComponent
                            placeholder={i18n._('amount')}
                            error={fieldState.error}
                            {...rhfField}
                        />
                        <ErrorMessage errors={errors} name={rhfField.name} />
                    </Flex>
                )}
            />
            <Controller
                name={`${namePrefix}.usedForDownpayment`}
                defaultValue={usedForDownpayment}
                render={({ field: rhfField, fieldState }) => (
                    <Flex flexDirection="column" flex={1}>
                        <MoneyInputComponent
                            placeholder={i18n._('usedForDownpayment')}
                            error={fieldState.error}
                            {...rhfField}
                        />
                        <ErrorMessage errors={errors} name={rhfField.name} />
                    </Flex>
                )}
            />
        </Flex>
    </Flex>
);

export const AssetsFields = ({ name }) => {
    const { i18n } = useI18n();
    const { errors } = useFormState();
    const { fields, append, remove } = useFieldArray<any, any, any>({
        name,
    });
    const [isAddingAsset, setIsAddingAsset] = useState(false);

    const addNewAsset = useCallback(
        (newType) => {
            append({ type: newType, amount: 0, usedForDownpayment: 0 });
            setIsAddingAsset(false);
        },
        [append]
    );

    return (
        <Flex flexDirection="column" margin={{ bottom: 'small' }}>
            {fields.map((field: any, index) => (
                <AssetInput
                    key={field.id}
                    namePrefix={`${name}.${index}`}
                    onRemove={() => remove(index)}
                    errors={errors}
                    i18n={i18n}
                    {...field}
                />
            ))}

            <Flex mt={3}>
                {isAddingAsset ? (
                    <Flex flex={1} justifyContent="space-between">
                        <SelectV2
                            placeholder={i18n._('selectNewAssetType')}
                            options={ASSET_TYPE_OPTIONS(i18n)}
                            onChange={addNewAsset}
                        />

                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => setIsAddingAsset(false)}
                            css={css({ marginLeft: 2 })}
                        >
                            <Trans id="cancel" />
                        </Button>
                    </Flex>
                ) : (
                    <Button
                        variant="primary"
                        type="button"
                        onClick={() => setIsAddingAsset(true)}
                    >
                        <Trans id="addNewAsset" />
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};
