import React, { useState } from 'react';
import { forwardRef } from 'react';

import { Grid } from 'components/grid/grid';
import { useI18n } from 'providers/i18n/use-i18n';
import { asYearsMonths, asMonths, getOnlyNums } from 'utils';

import { TextInputComponent } from './text-input';

export const DurationMonth = forwardRef(
    (
        { id, name, value, defaultValue, onChange, error, ...rest }: any,

        ref: any
    ) => {
        const { i18n } = useI18n();
        const [val, setVal] = useState(
            defaultValue || value
                ? asYearsMonths(defaultValue || value)
                : undefined
        );

        return (
            <Grid gridGap={10} gridTemplateColumns="1fr 1fr">
                <TextInputComponent
                    id={`${id || name}Year`}
                    name={`${name}.year`}
                    defaultValue={val?.years || undefined}
                    onChange={(e) => {
                        const fieldVal = e.currentTarget.value as string;

                        const onlyNums = getOnlyNums(fieldVal);

                        const newValue = onlyNums;

                        setVal({
                            years: newValue ? +onlyNums : 0,
                            months: val?.months || 0,
                        });

                        e.currentTarget.value =
                            newValue === '0' ? '0' : newValue || '';

                        onChange(
                            asMonths(newValue ? +onlyNums : 0, val?.months || 0)
                        );
                    }}
                    placeholder={i18n._('Years')}
                    css={{ minWidth: 'unset' }}
                    error={error}
                    {...rest}
                />
                <TextInputComponent
                    id={`${id || name}Month`}
                    name={`${name}.month`}
                    defaultValue={val?.months || undefined}
                    max={12}
                    placeholder={i18n._('Months')}
                    css={{ minWidth: 'unset' }}
                    onChange={(e) => {
                        const fieldVal = e.currentTarget.value as string;

                        const onlyNums = fieldVal.replace(/[^\d]/g, '');

                        const months = +onlyNums > 12 ? val?.months : +onlyNums;

                        const newValue = months;

                        setVal({
                            years: val?.years || 0,
                            months: newValue ? +newValue : 0,
                        });

                        e.currentTarget.value =
                            onlyNums === '0'
                                ? '0'
                                : onlyNums
                                ? String(newValue)
                                : '';

                        onChange(
                            asMonths(val?.years || 0, newValue ? +newValue : 0)
                        );
                    }}
                    error={error}
                    ref={ref}
                    {...rest}
                />
            </Grid>
        );
    }
);
